.custom-error-pricing-message {
  color: #B92020 !important; 
  text-align: left !important; 
  font-weight: 600 !important; 
  margin-top: -10px !important; 
  margin-bottom: 10px !important;
}


@media (max-width: 1400px) {
  .custom-error-pricing-message {
    text-align: center !important;
    margin-left: 2px;
  }
}