.input-box {
    background-color: #41B983;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 7px 26px;
    text-align: center;
    text-decoration: underline;
    font-size: 14px;

    input {
        height: 35px;
        text-align: center;
        box-shadow: 0px 3px 6px #00000029;
    }
}
