@import 'src/components/scss/Common';

.link-container {
  display: flex;
  align-items: center;
}

.link-anchor {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 16px;
  color: $colorBlack;
}

.text {
  margin-right: $space10;
}