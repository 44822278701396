@import "../../components/scss/Common";

.suggestion_modal {
  label {
    color: $colorTextSecondary;
  }

  input {
    text-align: center;
  }

  .pill-suggestion-day {
    padding: 5px 20px;
    border-radius: 12px;
    background-color: #E8E8E8;
    color: #000;
    cursor: pointer;

    &.active {
        background-color: #636060;
        color: #fff;
    }
  }
}
