@import 'Common';

.modal {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 200ms ease-in-out opacity, 200ms ease-in-out z-index, 200ms ease-in-out visibility;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  overflow-y: auto;

  .close-button {
    position: absolute;
    right: 22px;
    top: 22px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $colorGray;
    opacity: 0.5;
    z-index: -1;
  }

  input,
  select {
    background-color: $colorGray;
  }

  .container {
    display: inline-block;
    text-align: left;
    position: relative;
    background-color: $colorWhite;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    transition: 200ms ease-in-out transform;
    transform: scale(1.1);
    width: 490px;

    .body {
      padding: 45px 23px 30px 23px;

      .title {
        text-align: center;
        padding-bottom: $space32;
        border-bottom: 1px solid $colorDarkGray;
        margin-bottom: $space32;

        h2 {
          font-size: 22px;
          font-weight: bold;
          padding: 0;
          margin: 0;
        }
      }

      .controls {
        display: flex;
        margin-top: $space32;

        > * {
          margin: 0 $space6;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          width: 100%;
        }

        &.footerColumn {
            flex-direction: column;

            > * {
                margin: 6px 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
      }
    }
  }
  &.show {
    visibility: visible;
    z-index: 10000;
    opacity: 1;

    .container {
      transform: scale(1);
    }
  }

  &.invert-colors {
    input,
    select {
      background-color: $colorWhite;
    }

    .backdrop {
      background-color: #fff;
    }
    .container {
      background-color: $colorGray;
    }
  }
}
