@import "src/components/scss/Common";

.register-stock-check-modal {
  .container {
    width: 1000px;

    .title {
        margin-bottom: 16px;
    }

    input {
        text-align: left;
    }

    .checkbox-group {
        display: flex;

        .field-inputs {
            padding: 20px 20px;
            border-radius: 8px;
            background-color: #F0F0F0;

            label {
                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
  }
}
