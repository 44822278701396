@import "src/components/scss/Common";

.button-badge {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: $colorBlack;
  color: $colorWhite;
  font-size: 10px;
  line-height: normal;
  font-weight: normal;
  padding: 2px 5px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  margin-left: $space8;
}