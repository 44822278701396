@import "src/components/scss/Common";
a.back-button,
a.back-button:hover,
a.back-button:visited,
{
  display: inline-flex;
  align-items: center;
  font-style: normal;
  color: $colorBlack;
  font-size: 16px;

  img {
    margin-right: $space8;
  }
}
