@import "../../components/scss/Common.scss";

.crud-list {
    .user-collaborator-box {
        height: 62px;
        border-radius: 12px;
        background-color: $colorWhite;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

}

.select-cashier {
    text-align: center;
    text-align-last: center;
    appearance: none;
    opacity: 1;
}


.box-container-movement-page {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;

    .sales-by-mode-table {
        margin-top: 20px;

        &-title {
            font-weight: bold;
            padding: 10px;
            background-color: $colorMediumGrayOpacity05;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-body {
            background-color: $colorLightGray !important;
            max-height: 84px !important;
            height: 84px;

            .sales-by-mode-table-content {
                padding: 4px 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}