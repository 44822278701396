.order-billing {
    .field-inputs {
        label {
            margin: 0 8px;

            &:first-child {
                margin: 0;
            }
        }

        input {
            margin-right: 5px;
        }
    }

    .input-rateio-frete {
        display: flex;
        align-items: center;
        width: 80px;
        height: 30px;

        input {
            padding: 5px;
            border-radius: 12px;
            text-align: center;
        }
    }

    .total-row {
        td {
            background-color: #DCDCDC !important;
            font-weight: bold;
            cursor: default;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }

    .billing-totals {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        background-color: #DCDCDC;
        padding: 10px 20px;
        font-size: 22px;

        span {
            color: #E31616;
        }

        a {
            font-size: 19px;
            color: #000000;
            font-style: normal;
        }
    }
}
