@import "src/components/scss/Common";

.load-error {
  padding: 0 $space6;

  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $space12;
    font-style: italic;
    font-weight: $fontWeightSemibold;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      margin-right: $space6;
    }
  }

  .try-again {
    text-align: center;

    .button {
      max-width: 100%;
    }
  }
}