@import "src/components/scss/Common";

.product-table-container {
  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorWhite;
    transition: 200ms ease-in-out z-index, 200ms ease-in-out opacity;
    z-index: -1;
    opacity: 0;
    position: absolute;
    width: 100%;

    &.show {
      z-index: 5;
      opacity: 1;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead > tr {
      th {
        font-size: 14px;
        font-weight: bold;
        padding: 8px 0;
        background-color: #E3A208;
        text-align: center;
        line-height: 18px;
        border-bottom: 0;

        &:first-child {
          border-top-left-radius: 8px;
          padding: 8px 20px;
          text-align: left;
        }

        &:last-child {
          border-top-right-radius: 8px;
          padding: 8px 20px;
        }
      }
    }

    tbody {
      position: relative;
      margin-top: 4px;

      .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $colorWhite;
        transition: 200ms ease-in-out z-index, 200ms ease-in-out opacity;
        z-index: -1;
        opacity: 0;

        &.show {
          z-index: 5;
          opacity: 1;
        }
      }

      tr {
        td {
          padding: 8px 0;
          text-align: center;
          vertical-align: middle;
          transition: 100ms ease-in-out background-color;
          font-size: 16px;
          // font-weight: bold;
          line-height: 20px;

          &:first-child {
            padding: 8px 20px;
          }

          &:last-child {
            padding: 8px 20px;
          }
        }
      }
    }
  }
}
