@import "../../components/scss/Common";

.filter-collaborators-button {
  padding: 5px 20px;
  border-radius: 12px;
  background-color: #E8E8E8;
  color: #000;
  cursor: pointer;

  &.active {
      background-color: #636060;
      color: #fff;
  }
}

.tooltip-collaborators .tooltip-container  .tooltip{
  align-items: flex-start;

  .tooltip-text {
    top: 30%;
    transform: translateY(-50%);
  }
}
