@import "Common";
@import "Fonts";
@import "bootstrap/scss/bootstrap-grid.scss";
@import "bootstrap/scss/bootstrap-utilities.scss";

* {
  font-family: $fontSourceSansPro;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  vertical-align: middle;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

body {
  font-family: $fontSourceSansPro;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

.grecaptcha-badge {
  display: none;
}

.hide-mobile {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.hide-desktop {
  @include media-breakpoint-up(sm) {
    display: none !important;
  }
}

@import "Modal";
@import "NonLoggedScreen";
@import "Form";
@import "Grid";
@import "Crud";
@import "Margins";
@import "Paddings";
@import "Tooltip";
