@import "./scss/Common";

.bar-codes-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px;
    height: 220px;
    overflow: auto;
}

.bar-code-container {
    display: flex;
    justify-content: space-between;
    background: $colorGray;
    padding: 12px;
    border-radius: 16px;

    span {
        color: $colorBlack;
    }

    input {
        accent-color: $colorBlack;
        cursor: pointer;
    }
}

.action-container {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;

    input {
        accent-color: $colorBlack;
    }

    span {
        text-wrap: nowrap;
        margin-left: 4px;
    }
}