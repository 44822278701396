.total-cash {
    text-align: start !important;
    font-size: 20px !important;
}



.input-style {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.crud-list .money-container-body table tr .total-cash {
    padding: 8px 12px 10px 27px !important
}