
#bills-table {
    .billToPay {
        color: #8F1414,
    }

    .billToReceive {
        color: #0C42F1,
    }
}
