@import 'src/components/scss/Common';

$loadingSizeSmall: 40px;
$loadingSizeMedium: 48px;
$loadingSizeLarge: 56px;

.loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }

  .small {
    width: $loadingSizeSmall;
    height: $loadingSizeSmall;
  }
  .medium {
    width: $loadingSizeMedium;
    height: $loadingSizeMedium;
  }
  .large {
    width: $loadingSizeLarge;
    height: $loadingSizeLarge;
  }
}