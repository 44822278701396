@import "./scss/Common";

.import-file-modal {
  .container {
    // width: 1000px;
    // min-height: 740px;
  }

  .files-list {
    margin-top: 10px;
    list-style: none;

    li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }

        p.file-name {
            color: #000;
            word-break: break-all;
            text-align: left;
        }

        progress {
            width: 100px;
        }

        img {
            width: 18px;
            margin-left: 5px;
        }
    }
  }

  .file-drop-target {
    width: 100%;
    height: 100px;
    border: 3px dashed #707070;
    display: flex;
    align-items: center;
    justify-content: center;

    &.file-drop-target.file-drop-dragging-over-frame {
        border-color: #41B983;
        color: #41B983;
    }
  }

  .text-red {
    color: $colorRed;
  }
}
