.permissioned-link-container {

    .permissioned-link {
        text-decoration: none;
        font-style: normal;
    }

    .permissioned-link:hover {
        text-decoration: underline;
    }

    a,
    a:hover,
    a:visited {
        color: inherit !important
    }
}
