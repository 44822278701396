@import "Common";
@import 'GridCommon';


.gd {
  display: inline-flex;
  text-align: left;
  width: 100%;

  //> .gd-col {
  //  &:first-child {
  //    padding-left: 0;
  //  }
  //  &:last-child {
  //    padding-right: 0;
  //  }
  //}
}
@for $i from 1 through $cols {
    .gd-col-#{$i} {
      //display: inline-flex;
      $width: calc($colWidth * $i);
      $spacing: calc($colSpacing / 2);
      width: calc(#{$width});
      min-width: calc(#{$width});
      max-width: calc(#{$width});
      padding: 0 $spacing;

      > .button,
      > .field-text,
      > .field-live-search,
      > .field-pix,
      > select,
      > .table-container,
      > .field-permission-group,
      {
        width: 100%;
      }
    }
}

.gd-col--center {
  display: flex;
  justify-content: center;
}

.gd-col--between {
  display: flex;
  justify-content: space-between;
}

.gd-col--middle {
  display: flex;
  align-items: center;
}

.gd-col--bottom {
  display: flex;
  align-items: end;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.align-left {
  display: flex;
  justify-content: flex-start;
}

.gd-col--no-padding {
  padding: 0;
}

.gd--full-height,
.gd-col--full-height {
  height: 100%;
}
