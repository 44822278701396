.Opening-balance{
    display: flex;
    justify-content: center;
    label{
        font-size: 16px;
        font-weight: 600;
        margin: 18px 0 0px 0;
    }
}

