@import "../../components/scss/Common";

.crud-list-coupom-form {
    .custom-validation-error,
    .error-message,
    .validation-error {
        margin-top: 6px;
        margin-left: 4px !important;
        font-size: 16px;
        color: #b92020;
        font-weight: 600;
        text-align: left !important;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .user-list-coupon-container {
        grid-area: user-list-coupon;
        background-color: $colorLightGray;
        padding: 16px;
        border-radius: 8px;

        p {
            margin: 0;
        }

        .user-list-coupon-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                margin: 0;
            }
        }

        .user-list-coupon-content {
            margin-top: 16px;
            overflow: auto;
        }
    }

    .container-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 20px;

        .switches {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        margin-bottom: 20px;
    }
}
