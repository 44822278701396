@import "src/components/scss/Common";

.import-order-document-modal {
  .container {
    width: 1000px;

    .title {
        margin-bottom: 16px;
    }
  }
}
