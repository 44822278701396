.swich-toggle-recurring-box {
  height: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .switch-container {
    margin-left: 22px !important;
  }
}

.button-box-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}