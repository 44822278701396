.purchase-status-timeline__container {
    width: calc(100% - 14px);
    display: flex;
    background-color: #707070;
    height: 28px;
    border-radius: 9px;
    margin-left: 14px;
    align-items: center;
    justify-content: space-between;

    .purchase-status-timeline__item {
        width: 200px;
        position: relative;
        display: flex;
        align-items: center;
        color: #FFF;
        font-size: 16px;
        margin-right: 20px;

        &:first-child {
            margin-left: -14px;
        }

        .circle {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin-right: 10px;
            background-color: #E8E8E8;

            img {
                width: 20px;
                height: 20px;
            }

            &.active {
                background-color: #707070;
                box-shadow: 0px 3px 6px #00000029;
            }
        }

        .created-at {
            position: absolute;
            top: calc(28px + 10px);
            left: 50px;
            right: 0;
            width: 100%;
            font-size: 12px;
            color: #000;
        }
    }

    .tooltip {
        &::after {
            width: 13px;
            height: 13px;
            font-size: 12px;
        }

        .tooltip-text::before {
            width: 13px;
            height: 13px;
            font-size: 12px;
            margin-left: 5px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .purchase-status-timeline__container {
        width: calc(100% - 4px);
        margin-left: 7px;
        margin-bottom: 60px !important;
        .purchase-status-timeline__item  {
            &:first-child {
                margin-left: 10px;
            }

            .circle {
                width: 20px;
                height: 20px;
            }

            p {
                font-size: medium;
            }

            .created-at{
                left: 18px;
                p {
                    font-size: 14px;
                }
            }
        }
    }
}