@import "src/components/scss/Common";

.purchase-table {
  margin-bottom: 50px;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 4px 0 26px 0;

    thead {
      font-size: 14px;
      font-weight: bold;
      // padding: 3px;
      background-color: $colorGray;
      text-align: center;
      height: 26px;

    }

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }

    th {
      border-bottom: 0;
      background-color: #cbcbcb;
      height: 26px;
      padding: 4px 0;
      text-align: center;

      &:first-child {
        background-color: transparent;
      }

      &:nth-child(2) {
        background-color: transparent;
      }

      &:nth-child(3) {
        background-color: transparent;
      }

      &:nth-child(4) {
        border-top-left-radius: 8px;
        // padding-left: 27px;
      }

    }
  }

  tbody {
    position: relative;
    margin-top: 4px;
    height: 69px;

    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $colorWhite;
      transition: 200ms ease-in-out z-index, 200ms ease-in-out opacity;
      z-index: -1;
      opacity: 0;

      &.show {
        z-index: 5;
        opacity: 1;
      }
    }

    tr {
      td {
        background-color: $colorWhite;
        padding: 4px;
        text-align: center;
        vertical-align: middle;
        transition: 100ms ease-in-out background-color;
        font-size: 16px;

        &:first-child {
          border-radius: 8px 0 0 8px;
          text-align: left;
          padding-left: 15px;
        }

        &:nth-child(2) {
          text-align: left;
        }

        &:nth-child(3) {
          text-align: left;
        }

        &:last-child {
          border-radius: 0 0 8px;
        }
      }
    }
  }
}
