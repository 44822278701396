@import "../../components/scss/Common.scss";

.buttons-controll {
    display: flex;
    justify-content: end;
}
.crud-list {
    position: relative;
    .differencein-value {
        display: flex;
        justify-content: end;
        font-size: 22px;
        margin: 0 20px 14px 0;
    }


    .box-table {
        padding-inline: 10px;
        .facade {
            background-color: $colorWhite;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 10px;
            margin-bottom: 10px;
        }

        .table-container {
            text-align: center;
            display: contents;
        }

        .table-content {
            table {
                thead {
                    th:last-child {
                        border-top-right-radius: 0px !important;
                    }

                    th:first-child {
                        border-top-left-radius: 0px !important;
                    }
                }

                tr {
                    td {
                        padding: 10px !important;

                        .field-group {
                            margin-bottom: 0px !important;
                        }
                    }
                }
            }
        }
        .box-button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: end;
            .button-new-machine {
                background-color: transparent;
                font-style: italic;
                text-decoration: underline;
            }
        }
    }

    .money-container {
        padding: 0 10px 0 10px;
        &-title {
            display: flex;
            align-items: center;
            justify-content: end;
            padding: 4px 10px;
        }
        &-body {
            table {
                thead {
                    th:last-child {
                        border-top-right-radius: 0px !important;
                    }

                    th:first-child {
                        border-top-left-radius: 0px !important;
                    }
                }

                tr {
                    td {
                        padding: 0 10px !important;

                        .field-group {
                            width: 50%;
                        }
                    }

                    .field-center {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}