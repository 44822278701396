@import "src/components/scss/Common";

.side-menu {
    width: 250px;
    transition: .3s ease-out width;
    background-color: #FFF;
    position: relative;
    padding: 0 0 10px 0;

    &.collapsed {
        width: 66px;

        .profile-container-menu {
            .user-info,
            .user-controls {
                margin: 0;
                padding: 0;
                width: 0;
                visibility: hidden;

                .user-name, .job-title {
                    margin: 0;
                    padding: 0;
                    width: 0;
                    visibility: hidden;
                    transition: none;
                }
            }
        }

        .menus-container {
            .menu-item {
                .text {
                    transition: none;
                    width: 0;
                    visibility: hidden;
                }

                &:hover {
                    .text-tooltip {
                        display: block;
                        visibility: visible;
                    }
                }
            }
        }
    }

    .btn-toggle {
        background-color: #FFF;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, .25);
        border: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        left: calc(100% - 8px);
        font-weight: bold;
    }

    .profile-container-menu {
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        background-color: #fff;
        margin-bottom: 15px;

        .user-info {
            margin-left: 10px;

            .user-name {
                font-weight: bold;
                font-size: 20px;
                padding-right: $space8;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 147px;
                transition: .3s linear width, .3s linear visibility;
            }

            .job-title {
                font-size: 14px;
                color: $colorDarkGray;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 147px;
                transition: .3s linear width, .3s linear visibility;
            }
        }

        .user-controls {
            padding-right: $space6;
            overflow-x: hidden;
        }
    }

    hr {
        margin: 0 10px;
        margin-bottom: 15px;
    }

    .menus-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        height: calc(100vh - 98px);

        &::-webkit-scrollbar-thumb {
            width: 5px;
            background-color: #000;
        }

        &::-webkit-scrollbar {
            width: 2px;
        }

        .menu-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: transparent;
            border: none;
            padding: 16px 21px;

            .text {
                color: #000;
                font-size: 16px;
                margin-left: 8px;
                transition: .3s linear width, .3s linear visibility;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .items {
                display: none;
                background-color: #FFF;
                box-shadow: 4px 4px 4px rgba(0, 0, 0, .25);
                position: absolute;
                left: 100%;
                top: 0;
                width: 200px;
                z-index: 1000000000;

                p {
                    padding: 10px 8px 10px 14px;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: left;
                }

                ul {
                    list-style: none;

                    li {
                        text-align: left;

                        .item-link, .sub-item {
                            display: block;
                            padding: 10px 8px 10px 14px;
                            width: 100%;
                            text-align: left;
                            border: none;
                            background-color: transparent;
                            text-decoration: none;
                            color: #000;
                            font-style: normal;
                        }

                        .sub-item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            img {
                                width: 10px;
                                height: 10px;
                            }

                            &.is-open {
                                img {
                                    transform: rotateZ(180deg);
                                }
                            }
                        }

                        .sub-items {
                            li {
                                margin-left: 10px;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        &.active {
                            font-weight: bold;
                        }

                        &:hover {
                            background-color: #F6F6F6;
                        }
                    }
                }
            }

            .text-tooltip {
                left: 100%;
                top: 50%;
                display: none;
                background-color: #000;
                color: #FFF;
                padding: 5px 8px;
                margin-left: 10px;
                border-radius: 3px;
                visibility: hidden;
                position: absolute;
                z-index: 1000000000;

                .triangle {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -3px;
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-right: 5px solid #000;
                }
            }

            &:hover {
                background-color: #F6F6F6;

                .items {
                    display: block;
                }
            }

            &.active {
                padding: 16px 21px 16px 16px;
                background-color: #F6F6F6;
                border-left: 5px solid #41b983;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        position: fixed;
        width: 100%;
        z-index: 1000000000;

        &.collapsed {
            display: none;
        }

        .menus-container {
            height: calc(100vh - 190px);
        }

        .profile-container-menu {
            padding: 10px 35px;
        }

        .profile-container {
            margin-bottom: 20px;
        }

        .menu-item {
            display: block !important;

            .items {
                margin-top: 15px;
                width: 100% !important;
                position: initial !important;

                li:hover {
                    background-color: #FFF !important;
                }

                p {
                    display: none;
                }
            }
        }

    }
}
