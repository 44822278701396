@import "src/components/scss/Common";

.pills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  .pill {
    display: inline-flex;
    background-color: $colorWhite;
    color: $colorBlack;
    box-shadow: 0 3px 6px #00000029;

      padding: 8px 12px;
      border-radius: 16px;
      margin: 4px 0;

    .button {
      margin-right: $space8;
    }

    .text {
      margin: 0 65px;
      font-size: 12px;
        display: contents;
    }
  }
}

.modal {
  .pills-container {
    .pill {
      background-color: $colorGray;
    }
  }
}
