@import "src/components/scss/Common";

.life-search-table {
  tbody {
    tr {
      td {
        background-color: transparent;
        padding: 7.5px $space4;

        &.controls {
          width: 1px;
          white-space: nowrap;
          vertical-align: middle;

          button {
            display: inline-block;
            vertical-align: middle;
            margin-right: $space20;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &:hover,
      &:focus {
        td {
          background: unset;
        }
      }
    }
  }
}