@import "Common";

@mixin font($fontFamily, $fontFileNameWithoutExtension, $fontWeight, $fontStyle) {
  @font-face {
    font-family: $fontFamily;
    src: url('../fonts/#{$fontFileNameWithoutExtension}.woff2') format('woff2'),
    url('../fonts/#{$fontFileNameWithoutExtension}.woff') format('woff');
    font-weight: $fontWeight;
    font-style: $fontStyle;
  }
}

@include font($fontSourceSansPro, 'sourcesanspro-regular', normal, normal);
@include font($fontSourceSansPro, 'sourcesanspro-italic', normal, italic);
@include font($fontSourceSansPro, 'sourcesanspro-light', $fontWeightLight, normal);
@include font($fontSourceSansPro, 'sourcesanspro-light-italic', $fontWeightLight, italic);
@include font($fontSourceSansPro, 'sourcesanspro-bold', bold, normal);
@include font($fontSourceSansPro, 'sourcesanspro-bold-italic', bold, italic);
@include font($fontSourceSansPro, 'sourcesanspro-semibold', $fontWeightSemibold, normal);
@include font($fontSourceSansPro, 'sourcesanspro-semibold-italic', $fontWeightSemibold, italic);

.color- {
    &black {
        color: $colorBlack;
    }
}
