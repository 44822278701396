.customer-model {
    width: 100%;

    .container {
        width: 1000px !important;
    }

    .container-customer {
        display: flex;
        flex-direction: column;
        justify-content: left;
        text-align: left;
        width: 100%;
    }

    .fullname {
        display: flex;
        gap: 10px;
    }

    .infos {
        display: flex;
    }

    .address {
        .modal-address {
            height: 770px !important;
        }
    }
}
