@import "src/components/scss/Common";

.edit-user-picture-modal {
  .body {
    text-align: center;

    .input-grid {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: min-content 1fr;
      align-items: center;
      justify-items: end;

      label {
        margin: 0;
        padding: 0 $space12 0 0;
      }
    }
  }
}

.modal-width {
  .container {
    width: 50% !important;
    min-width: 650px;
  }
}