@import "Common";

.non-logged-screen {
  .container {
    display: flex;
    flex-direction: column;
    height: 740px;

    .body {
      padding: $space50 $space32 0;
      flex: 1;

      .logo-container {
        text-align: center;
        margin-bottom: $space60;
      }
      h1 {
        padding: 0;
        margin: 0 0 $space35;
        font-size: 18px;
        font-weight: normal;
        color: $colorGreen;
        text-align: center;
      }
      form {
        .forgot-password {
          text-align: right;
        }

        .button-container {
          padding-top: $space16;

          .button {
            width: 100%;
          }
        }
      }
    }
    .foot {
      font-size: 16px;
      color: $colorDarkGray;
      padding: $space16;

      a {
        font-style: normal;
      }

      .content {
        padding: $space20 $space42 $space10;
        border-top: 1px solid $colorMediumGray;
      }
    }
  }

  &.no-logo {
    .container {
      .body {
        padding: $space50 $space32;
        h1 {
          margin-top: $space32;
          color: $colorBlack;
        }

        .instructions {
          margin-top: $space20;
          font-size: 16px;
          color: $colorDarkGray;
          text-align: center;

          &.margin-bottom {
            margin-bottom: $space32;
          }
        }
      }
    }
  }
}