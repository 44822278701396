.total-row {
    td {
        background-color: #DCDCDC !important;
        font-weight: bold;
        cursor: default;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
}

.customer {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
  
      p {
          margin: 0;
          font-size: 16px;
  
          &:last-child {
              font-style: italic;
              font-size: 14px;
              margin-top: 6px;
          }
      }
    }
}