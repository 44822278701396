@import "src/components/scss/Common";

$colorPaginationContainer: #E3E3E3;

.pagination {
  align-self: center;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $colorPaginationContainer;
  border-radius: 22px;
  padding: $space8 $space16;
  margin-top: $space12;
  font-size: 16px;
  min-width: 200px;

  .control-container {
    display: inline-flex;
    align-items: center;
  }

  .page-info {
    padding: 0 $space12;
    flex: 1;
    text-align: center;
  }
}
