@import "src/components/scss/Common";

.amount {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $colorWhite;
    text-align: center;
    padding: $space20;
    height: 82px;
    padding: 10px 20px;

    p {
        width: 100%;
        font-weight: 600;
        font-size: 18px;
        text-align: left;

        &.inline {
            font-size: 22px;
            text-align: center;
            font-weight: normal;

            span {
                font-size: 22px;
            }
        }
    }

    span {
        font-size: 32px;
    }
}
