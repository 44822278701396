@import "src/components/scss/Common";

.pill-suggestion-movement {
  padding: 5px 20px;
  border-radius: 12px;
  background-color: #E8E8E8;
  color: #000;
  cursor: pointer !important;

  &.active {
    background-color: #636060;
    color: #fff;
  }

  text-align: center;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

}