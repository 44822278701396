
.supplier-representative {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background-color: #FFF;

    input {
        margin-right: 16px;
        width: 20px;
        height: 20px;
    }

    &--socialReason {
        width: 56%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &--info {
        width: calc(100% - 36px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 !important;
        font-size: 16px;
        font-weight: normal;

        div {
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
        }
    }
}
