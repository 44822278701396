.tags {
    .table-container .table thead th {
        padding: 25px 1px 25px 2px !important;
        width: 10px;
    }
}

.product-tag-row {


    .field-group {
        margin-bottom: 0;
    }


    .checkbox-tags {
        width: 20px;
        height: 20px;
        border: 2px solid #000000;
        border-radius: 3px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        background-color: transparent;
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
    }


    .checkbox-tags:checked {
        background-color: black;
        background-image: url('../../components/images/check.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

    }

    .product-tag-row.selected {
        background-color: #e1f6ed;
    }

}

.checkBoxTags-label.disabled {
    display: none;
}

.checkBoxTags-container {
    border-radius: 8px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    background-color: #ECECEC;
    height: 3rem;
    margin-left: 34px;

    label {
        margin: 0 25px 0 0;
        cursor: pointer;
    }

    .checkbox-div {
        display: flex;
        align-items: center;
        margin-left: 30px;
    }


    .checkBoxTags-label {
        padding: 10px;
        text-decoration: underline;
        overflow: visible;
        font-weight: bold;

    }

    .checkbox-all {
        display: flex;
        align-items: center;
        cursor: pointer;
        appearance: none;
        padding: 9px 9px;
        position: relative;
        border: 2px solid #000000;
        border-radius: 4px;
        background-color: transparent;
        box-sizing: border-box;
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);


    }


    .checkbox-all:checked::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .checkbox-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        appearance: none;
        padding: 8px 8px;
        border-radius: 50%;
        border: 2px solid #000000;
        background-color: #ECECEC;
        box-sizing: border-box;
        position: relative;
        margin-right: -1px;

    }


    .checkbox-circle:checked::before {
        content: '';
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


}


@media screen and (max-width: 1490px) and (max-height: 800px) {

    .checkBoxTags-container .checkBoxTags-label {
        font-size: 11px;
    }



    .checkBoxTags-container .checkbox-div {
        margin-left: -13px;
    }

    .checkBoxTags-container .checkbox-all {
        margin-left: 42px;
        padding: 8px 8px;
        margin-right: 27px;
    }

}