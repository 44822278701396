@import "src/components/scss/Common";
@import "src/components/PaymentList";

.sales-payment-modal {

   .title {
        font-size: 30px;
    }
    .flex-container{
        display: contents;
        .field-cpf{
            input{
                width: 57%;
            }
        }
    }
    .container {
        width: 899px !important;
        .body {
            padding: 28px 55px 73px 49px;
        }
    }
    .value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 100%
    }
    .screen-header {
        padding: 0;

        h1 {
            line-height: 32px;
            color: $colorBlackOpacity08;
        }

        .breadcrumb {
            li {
                font-size: 14px;
                line-height: 20px;
                color: $colorBlackOpacity08;

                &:last-child {
                    font-weight: bold;
                }
            }
        }
    }
    .balance-to-pay{
        opacity: revert;
    }

    .container-button-new-field {
        margin-bottom: 20px;
        border-bottom: 2px solid $colorMediumGrayOpacity05;
        .add-new-field{
            justify-content: end;
        }
        Button{
        
            padding: 20px 0 0 0;
        }

        .fieldCpf{
            width: 40%;
            margin: -11px 0 0 0;
        }

    }

    .button-new-field, .button-new-field:hover {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: $colorBlackOpacity08;
        text-decoration: underline;
        margin-bottom: 10px;
        padding-right: 0;
        justify-content: end;
    }
    .button-new-field{
        width: 28% !important;
    }
    .totals-gd {
        margin-bottom: 5px;

        .totals-container {
            display: flex;
            flex-direction: column;
            padding: 0;
            gap: 12px;

            .total-payment-list {
                box-shadow: 0px 3px 6px $colorBlackBoxShadow;
                border-radius: 12px;
                padding: 11px 30px 8px 16px;

                .col-styled {
                    align-items: center;

                    span {
                        font-size: 24px;
                        line-height: 35px;
                        color: $colorBlackOpacity08 !important;
                        opacity: revert !important;

                        &.value {
                            font-size: 32px;
                            line-height: 46px;
                        }
                    }
                }

                &:nth-last-child(-2n+2) {
                    span {
                        color: $colorOrange;
                        opacity: .8;

                        &.value {
                            font-weight: 600;
                        }
                    }
                }

                &.green {
                    background-color: $colorGreenOpacity05;
                }

                &.red {
                    background-color: $colorRedOpacity05;
                }

                &:last-child {

                    .value {
                        color: $colorWhite;
                        font-weight: 600;
                        opacity: .8;

                    }
                }
            }
        }
    }

    .justify-content-end {
        display: flex;
        justify-content: end;
    }

    .controls {
        justify-content: end;
        gap: 20px;

        button {
            width: 175px !important;
            height: 50px !important;
            font-size: 14px;
            line-height: 20px;


        }

        .button-color-gray {
            background-color: $colorDarkGray;

        }

        .button-color-green {
            background-color: $colorMediumGreen;

            .button-content {
                color: $colorBlack;
            }
        }
    }
}
