.modal-banner .container {
    width: 1440px;

    .validation-error {
        text-align: left;
    }

    .section-header {
        color: #000;
    }

    .image-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .image {
        width: 100%;
        max-height: 420px;
        object-fit: contain;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, .25);
    }

    .alert {
        border: 1px solid transparent;
        padding: 1rem 1rem;
        border-radius: .25rem;
        color: #842029;
        background-color: #f8d7da;
        border-color: #f5c2c7;
    }
}

.active-banner {
    label {
        margin-left: 0;
    }
}

.life-search-table {
    td {
        width: 100%;

        &.controls {
            margin-top: 0 !important;
            width: fit-content !important;
        }
    }
}

.image-button {
    width: 100%;
    display: flex;
    height: 250px;
    border: 1px solid #000 !important;
}
