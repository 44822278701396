.order-receive {
    .product-card {
        padding: 16px 8px;
        background-color: #FFF;
        border-radius: 6px;
    }

    .select-volume {
        text-decoration: underline;
        position: relative;
        cursor: pointer;
        width: fit-content;
        align-self: flex-end;
        justify-self: flex-end;

        .select-volume__items {
            display: none;
            width: 100%;
            padding: 16px 8px;
            border-radius: 6px;
            position: absolute;
            top: 25px;
            z-index: 1000000;
            right: 0;
            left: 0;
            background-color: #FFF;
            box-shadow: 0 2px 6px rgba(0, 0, 0, .25);

            ul {
                list-style: none;
            }

            &.active {
                display: block;
            }
        }
    }
}
