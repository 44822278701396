@import "src/components/scss/Common";

.picture-container {
  position: relative;
  max-width: 100%;
  max-height: 100%;

  .picture {
    font-weight: $fontWeightSemibold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $colorGray;
    box-shadow: 0 3px 6px #00000029;
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;

    &.size-main-menu {
      $size: 46px;
      width: $size;
      height: $size;
    }

    &.size-normal {
      $size: 50px;
      width: $size;
      height: $size;
    }

    &.size-large {
      $size: 100px;
      width: $size;
      height: $size;
    }

    &.size-product {
      width: 400px;
      height: 300px;
      background-color: $colorWhite;
    }

    .image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .initials {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .edit-picture {
    position: absolute;
    $size: 24px;
    width: $size;
    height: $size;
    background-color: $colorWhite;
    border-radius: 50%;
    box-shadow: 0 3px 6px #00000029;
    bottom: $space6;
    right: $space6;

    input[type='file'] {
      display: none;
    }
  }

  &.user-style-rounded {
    .picture {
      border-radius: 50%;
    }
  }

  &.user-style-square {
    .picture {
      border-radius: 12px;
    }
  }

  &.store-style-rectangle {
    border-radius: 0;

    .edit-picture {
      top: $space16;
      right: $space16;
      border-radius: 0;
      box-shadow: none;
    }

  }
}