@import "src/components/scss/Common";

.info-modal {
  .container {
    .body {
      color: $colorDarkGray;
      text-align: center;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
      .text {
          margin: 15px 0 -15px 0;
          font-size: 20px;
          color: black;
      }

        img {
          padding-bottom: $space16;
        }
      }
    }
  }

  &.info {
  }
  &.success {
    .container {
      .body {
        .title {
          color: $colorGreen;
        }
      }
    }
  }
  &.error {
    .container {
      .body {
        .title {
          color: $colorRed;
        }
      }
    }
  }
  &.small {
    .container {
      width: 385px;
      .close-button {
        width: 22px;
        height: 22px;
      }
      .body {
        .title {
          flex-direction: row;
          border-bottom: none;
          padding: 0;
          margin-bottom: $space16;
          img {
            width: 32px;
            height: 32px;
          }
          h2 {
            padding-top: 0;
            padding-left: $space12;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}