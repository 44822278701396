// margins
.m {
    &-4 {
        margin: $space4!important;
    }
    &-6 {
        margin: $space6!important;
    }
    &-8 {
        margin: $space8!important;
    }
    &-10 {
        margin: $space10!important;
    }
    &-12 {
        margin: $space12!important;
    }
    &-14 {
        margin: $space14!important;
    }
    &-16 {
        margin: $space16!important;
    }
    &-18 {
        margin: $space18!important;
    }
    &-20 {
        margin: $space20!important;
    }
    &-24 {
        margin: $space24!important;
    }
    &-28 {
        margin: $space28!important;
    }
    &-30 {
        margin: $space30!important;
    }
    &-32 {
        margin: $space32!important;
    }
    &-30 {
        margin: $space30!important;
    }
    &-35 {
        margin: $space35!important;
    }
    &-42 {
        margin: $space42!important;
    }
    &-50 {
        margin: $space50!important;
    }
    &-60 {
        margin: $space60!important;
    }
    &-80 {
        margin: $space80!important;
    }
    &-120 {
        margin: $space120!important;
    }

  // margin top
  &t {
    &-4 {
        margin-top: $space4!important;
    }
    &-6 {
        margin-top: $space6!important;
    }
    &-8 {
        margin-top: $space8!important;
    }
    &-10 {
        margin-top: $space10!important;
    }
    &-12 {
        margin-top: $space12!important;
    }
    &-14 {
        margin-top: $space14!important;
    }
    &-16 {
        margin-top: $space16!important;
    }
    &-18 {
        margin-top: $space18!important;
    }
    &-20 {
        margin-top: $space20!important;
    }
    &-24 {
        margin-top: $space24!important;
    }
    &-28 {
        margin-top: $space28!important;
    }
    &-30 {
        margin-top: $space30!important;
    }
    &-32 {
        margin-top: $space32!important;
    }
    &-30 {
        margin-top: $space30!important;
    }
    &-35 {
        margin-top: $space35!important;
    }
    &-42 {
        margin-top: $space42!important;
    }
    &-50 {
        margin-top: $space50!important;
    }
    &-60 {
        margin-top: $space60!important;
    }
    &-80 {
        margin-top: $space80!important;
    }
    &-120 {
        margin-top: $space120!important;
    }
  }

  // margin bottom
  &b {
    &-4 {
        margin-bottom: $space4!important;
    }
    &-6 {
        margin-bottom: $space6!important;
    }
    &-8 {
        margin-bottom: $space8!important;
    }
    &-10 {
        margin-bottom: $space10!important;
    }
    &-12 {
        margin-bottom: $space12!important;
    }
    &-14 {
        margin-bottom: $space14!important;
    }
    &-16 {
        margin-bottom: $space16!important;
    }
    &-18 {
        margin-bottom: $space18!important;
    }
    &-20 {
        margin-bottom: $space20!important;
    }
    &-24 {
        margin-bottom: $space24!important;
    }
    &-28 {
        margin-bottom: $space28!important;
    }
    &-30 {
        margin-bottom: $space30!important;
    }
    &-32 {
        margin-bottom: $space32!important;
    }
    &-30 {
        margin-bottom: $space30!important;
    }
    &-35 {
        margin-bottom: $space35!important;
    }
    &-42 {
        margin-bottom: $space42!important;
    }
    &-50 {
        margin-bottom: $space50!important;
    }
    &-60 {
        margin-bottom: $space60!important;
    }
    &-80 {
        margin-bottom: $space80!important;
    }
    &-120 {
        margin-bottom: $space120!important;
    }
  }

  // margin left
  &l {
    &-4 {
        margin-left: $space4!important;
    }
    &-6 {
        margin-left: $space6!important;
    }
    &-8 {
        margin-left: $space8!important;
    }
    &-10 {
        margin-left: $space10!important;
    }
    &-12 {
        margin-left: $space12!important;
    }
    &-14 {
        margin-left: $space14!important;
    }
    &-16 {
        margin-left: $space16!important;
    }
    &-18 {
        margin-left: $space18!important;
    }
    &-20 {
        margin-left: $space20!important;
    }
    &-24 {
        margin-left: $space24!important;
    }
    &-28 {
        margin-left: $space28!important;
    }
    &-30 {
        margin-left: $space30!important;
    }
    &-32 {
        margin-left: $space32!important;
    }
    &-30 {
        margin-left: $space30!important;
    }
    &-35 {
        margin-left: $space35!important;
    }
    &-42 {
        margin-left: $space42!important;
    }
    &-50 {
        margin-left: $space50!important;
    }
    &-60 {
        margin-left: $space60!important;
    }
    &-80 {
        margin-left: $space80!important;
    }
    &-120 {
        margin-left: $space120!important;
    }
  }

  // margin right
  &r {
    &-4 {
        margin-right: $space4!important;
    }
    &-6 {
        margin-right: $space6!important;
    }
    &-8 {
        margin-right: $space8!important;
    }
    &-10 {
        margin-right: $space10!important;
    }
    &-12 {
        margin-right: $space12!important;
    }
    &-14 {
        margin-right: $space14!important;
    }
    &-16 {
        margin-right: $space16!important;
    }
    &-18 {
        margin-right: $space18!important;
    }
    &-20 {
        margin-right: $space20!important;
    }
    &-24 {
        margin-right: $space24!important;
    }
    &-28 {
        margin-right: $space28!important;
    }
    &-30 {
        margin-right: $space30!important;
    }
    &-32 {
        margin-right: $space32!important;
    }
    &-30 {
        margin-right: $space30!important;
    }
    &-35 {
        margin-right: $space35!important;
    }
    &-42 {
        margin-right: $space42!important;
    }
    &-50 {
        margin-right: $space50!important;
    }
    &-60 {
        margin-right: $space60!important;
    }
    &-80 {
        margin-right: $space80!important;
    }
    &-120 {
        margin-right: $space120!important;
    }
  }
}
