@import 'src/components/scss/Common';

$colorResultContainer: #cbcbcb;

.field-live-search {
  position: relative;

  .field-text {
    .input-container {
      position: relative;

      .scroll-container {
        visibility: hidden;
        opacity: 0;
        max-height: 200px;
        overflow-y: auto;
        z-index: -1;
        top: 100%;
        margin-top: 3px;
        position: absolute;
        background-color: $colorWhite;
        width: 100%;
        border-radius: 8px;
        transition: 200ms ease-in-out visibility, 200ms ease-in-out z-index, 200ms ease-in-out opacity, 200ms ease-in-out padding;
        box-shadow: 0 3px 6px #00000029;

        .result-container {
          display: flex;
          flex-direction: column;

          .link-container {
            padding: $space12 $space20;
          }

          .empty-container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 $space12;
            font-style: italic;
            font-weight: lighter;

            > div {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .item {
            cursor: pointer;
            transition: 100ms ease-in-out background-color, 100ms ease-in-out color, 200ms ease-in-out padding;
            padding: $space12 $space20;

            &:hover,
            &:focus {
              color: $colorBlack;
              background-color: $colorLightGreen;
            }

            &.selected {
              background-color: $colorDarkGray;
              color: $colorWhite;
            }

            &:hover.selected,
            &:focus.selected {
              background-color: $colorDarkGray;
              outline: 3px solid $colorLightGreen;
              color: $colorWhite;
            }

            .text {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    &.show-result {
      .input-container {
        .scroll-container {
          visibility: visible;
          z-index: 5;
          opacity: 1;
          padding: $space12 0;
        }
      }
    }
  }

  &.multiple-selection {
    .field-text {
      .input-container {
        .scroll-container {
          .result-container {
            .item {
              cursor: unset;
              display: grid;
              grid-template-rows: auto;
              grid-template-columns: 1fr min-content;
              align-items: center;
            }
          }
        }
      }
    }
  }
}