@import "Common";

.crud-list {
  .controls {
    margin-bottom: $space42;
  }

  .table-container {
    text-align: center;
  }
}

.crud-form {
  display: flex;
  flex: 1;
  flex-direction: column;

  .controls {
  }

  .loading {
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}
