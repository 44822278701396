@import "Common";

form {
  text-align: left;
}

label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: $colorBlack;
  margin-left: 16px;
  margin-bottom: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

select,
input,
textarea {
  width: 100%;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  padding: $space20 $space18;
  background-color: $colorWhite;
  background-repeat: no-repeat;
}

textarea {
  resize: vertical;
}

select {
  appearance: none;
}

input {
  &::placeholder {
    color: $colorDarkGray;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

input[type="date"]::-webkit-clear-button {
  display: none;
  -moz-appearance: none;
}

.field-group {
  margin-bottom: $space20;

  &.error {
    input {
      border: 1px solid $colorRed;
    }

    .validation-error {
      margin-top: $space6;
      margin-left: $space18;
      font-size: 16px;
      color: $colorRed;
      font-weight: $fontWeightSemibold;
    }
  }
}

a,
a:hover,
a:visited,
{
  color: $colorDarkGray;
  font-style: italic;
  cursor: pointer;
  text-decoration: underline;
}

button {
  cursor: pointer;
}

.field-normal {
}

.field-login {
  input {
    font-size: 18px;
    padding: $space20 $space18;
  }

  label {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: $space12;
  }
}

.label-above {
}

.label-inline {
  position: relative;

  label {
    position: absolute;
    top: 50%;
    margin-top: -8.5px;
    width: calc(100% - #{$space18 * 2});
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: text;
    font-weight: normal;
    z-index: 5;
  }

  &:focus-within {
    label {
      color: transparent;
    }
  }
}

.field-login.label-inline {
  label {
    margin-top: -11px;
  }
}

.has-icon {
  select,
  input {
    $size: 18px;
    background-size: $size;
    background-position: right $size center;
    padding-right: 3 * $size; // icon weight is 18px
  }

  &.small-icon {
    select,
    input {
      $size: 12px;
      background-size: $size;
      background-position: right $size center;
      padding-right: 3 * $size; // icon weight is 18px
    }
  }

  select{
    background-size: unset;
  }


  input[type="date"] {
    padding-right: $space18;
  }

  &.label-inline {

    input {
      font-size: 14px;
      padding-top: $space18;
      padding-bottom: $space18;
      border-radius: 16px;
    }

    label {
      width: calc(100% - #{$space18 * 3});
    }
  }
}


table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    border-radius: 12px 12px 0 0;
    overflow: hidden;

    th {
      background-color: $colorWhite;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      vertical-align: middle;
      border-bottom: 6px solid $colorGray;

      &:first-child {
        border-top-left-radius: 12px;
      }
      &:last-child {
        border-top-right-radius: 12px;
      }

      .table-header-container {
        display: flex;
        align-items: center;

        .button-sort .button-content {
          display: inline-flex;
          align-items: center;

          .icon-sort {
            display: inline-flex;
            flex-direction: column;
            margin-right: $space8;

            .icon-ascendant {
              padding: 1.5px;
            }

            .icon-descendant {
              padding: 1.5px;
            }

            &.ascendant {
              .icon-descendant {
                visibility: hidden;
              }
            }

            &.descendant {
              .icon-ascendant {
                visibility: hidden;
              }
            }

            &.none {
              transition: 200ms ease-in-out opacity;
              opacity: 0;
            }
          }

          &:hover,
          &:focus {
            .icon-sort {
              &.none {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  tbody {
    position: relative;
    margin-top: 4px;

    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $colorWhite;
      transition: 200ms ease-in-out z-index, 200ms ease-in-out opacity;
      z-index: -1;
      opacity: 0;

      &.show {
        z-index: 5;
        opacity: 1;
      }
    }

    tr {
      td {
        background-color: $colorWhite;
        text-align: left;
        vertical-align: middle;
        transition: 100ms ease-in-out background-color;

        &.center {
          text-align: center;
        }

        &.right {
          text-align: right;
        }

        &.empty {
          font-style: italic;
          text-align: center;
          vertical-align: middle;
          padding: $space32 $space12;
          color: $colorDarkGray;
        }
      }

      &:hover {
        td {
          background-color: $colorLightGreen;
        }
      }
    }
  }
}

.field-inputs {
  label {
    display: inline-flex;
    align-content: center;
    font-weight: normal;
    margin: 0 $space18 0;

    input {
      display: block;
      margin-right: $space12
    }
  }
}

fieldset {
  display: block;
  border: none;
  padding: $space16 0;
  margin: 0;

  legend {
    display: block;
    width: 100%;
    background-color: $colorOrange;
    padding: $space4 $space20;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
  }

  .legend-gray {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    background-color: $colorMediumGray;
    padding: $space4 $space20;
    font-size: 14px;
    font-weight: bold;
    border-radius: 0 0 0 0;
  }
}
