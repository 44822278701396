@import "src/components/scss/Common";

.main-screen {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: $colorGray;
  overflow: hidden;

  .screen {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 14px 14px;
    transition: 200ms ease-in-out opacity;
    opacity: 0;
    overflow-y: scroll;
    overflow: auto;

    &.ready {
      opacity: 1;
    }
  }
}

.product-main-screen {
  background-color: $colorWhite;
  .screen {
    padding-top: 0;
  }
}
