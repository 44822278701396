@import "src/components/scss/Common";

@mixin collapseGroup() {
  .group-header {
    background-color: $colorLightGray;
    border-radius: 8px;

    .group-title {
      color: $colorGreen;
    }
  }

  .group-items {
    overflow: hidden;
    max-height: 0 !important;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;

    .menu-item {
      margin-bottom: $space6;
    }
  }

  .group-collapse {
    .collapse-button {
      opacity: 0;
    }
  }
}

.hide-desktop {
    .main-menu-group {
      overflow: hidden;
      margin-bottom: $space6;
      opacity: 0;
      transition: 200ms ease-in-out opacity;

      .group-header {
        transition: 200ms ease-in-out background-color, 200ms ease-in-out border-radius;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $colorGreen;
        height: 56px;
        border: none;
        margin: 0;
        padding: 0;
        width: 100%;
        border-radius: 8px 8px 0 0;

        .group-icon {
          margin: 0 $space20;
          width: 25px;
          transition: 200ms ease-in-out padding-left;
          display: flex;
          justify-content: center;
        }

        .group-title {
          transition: 200ms ease-in-out color, 200ms ease-in-out width, 200ms ease-in-out padding-left, 200ms ease-in-out visibility;
          flex: 1;
          text-align: left;
          font-size: 18px;
          color: $colorWhite;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .group-collapse {
          height: 100%;
          padding: 4px;
          overflow-x: hidden;

          .collapse-button {
            display: flex;
            align-items: center;
            transition: 200ms ease-in-out opacity;
            opacity: 1;
            padding: 8px;
            background-color: #93d7b8;
            border-radius: 0px 8px 0px 0px;
            width: 100%;
            height: 100%;
          }
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .group-items {
        transition: 200ms ease-in-out max-height, 200ms ease-in-out padding, 200ms ease-in-out opacity;
        margin-top: 3px;
        padding: $space18 0 $space18 $space20;
        background-color: $colorLightGray;
        border-radius: 0 0 10px 0;

        .menu-item {
          transition: 200ms ease-in-out margin;
          display: flex;
          align-items: center;
          margin: 0 0 $space28;
          padding: 0;
          border: none;
          background: transparent;
          font-style: normal;
          text-decoration: none;

          &:last-of-type {
            margin-bottom: 0;
          }

          .menu-item-bullet {
            display: flex;
            transition: transform .1s linear;

            &.selected {
                transform: rotateZ(90deg);
            }
          }

          .menu-item-text {
            font-size: 16px;
            color: $colorBlack;
          }

          &.selected {
            font-weight: bold;
          }

          &.collapse-menu-item {
            margin-bottom: 28px;
            cursor: pointer;

            .menu-item-text {
              padding-left: $space12;
            }
          }
        }
      }

      >* {
        transition-duration: 0ms;
      }

      &.ready {
        opacity: 1;

        >* {
          transition-duration: 200ms;
        }
      }

      &.collapsed {
        @include collapseGroup();
      }

      .menu-item-children {
        margin-top: -20px;
        margin-bottom: 26px;
        margin-left: 8px;
        padding: 4px 0;
        padding-left: 12px;
        border-left: 1px solid #000;
      }
    }
}

.hide-mobile {
    .group-header {
        width: 100%;
        background-color: #FFF;
        border: none;

    }
}

