@import "../../components/scss/Common";

.product-images-modal {
   .container {
      width: 100% !important;
      max-width: 1400px;

      .body {
         padding: 24px;

         .title {
            padding-bottom: 16px;
            margin-bottom: 16px;
         }
      }
   }
}

.add-image-btn-container {
   display: flex;
   width: 100%;
   justify-content: flex-end;
   margin-top: 32px;
}

.swiper-container {
   width: 45%;
   height: 600px;
}

.manipulating-images-container {
   width: 55%;
   padding: 0 32px 32px 40px;
}

.image-list-container {
   display: flex;
   gap: 24px;
   flex-wrap: wrap;
   margin-top: 40px;

   .image-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
   }
}

.image {
   width: 104px;
   border-radius: 4px;
   box-shadow: 0px 3px 6px $colorBlackBoxShadow;
}

.images-slider {
   height: 100%;
   width: 100%;
   max-height: 500px;
   max-width: 500px;

   .swiper-button-prev {
      color: $colorBlack;
   }

   .swiper-button-next {
      color: $colorBlack;
   }
}

.images-slider-thumbs {
   margin-top: 16px;
   height: 104px;
   width: 440px;

   .swiper-slide {
      cursor: pointer;
   }
}
