@import "./scss/Common";

.search-product-modal {
  .container {
    width: 1000px !important;
    min-height: 740px;
  }
}

.selected-product {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background-color: $colorLightGreen;
  padding: $space14;

  span {
    margin-right: 123px;
  }
}
