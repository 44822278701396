.modal-feedback .container {
  width: 800px;
  max-width: 1440px;

  .validation-error {
      text-align: left;
  }

  textarea {
    background-color: #E8E8E8;
    min-height: 100px;
  }

  .alert {
      border: 1px solid transparent;
      padding: 1rem 1rem;
      border-radius: .25rem;
      color: #842029;
      background-color: #f8d7da;
      border-color: #f5c2c7;
  }
}

.active-feedback {
  label {
      margin-left: 0;
  }
}

.life-search-table {
  td {
      width: 100%;

      &.controls {
          margin-top: 0 !important;
          width: fit-content !important;
      }
  }
}

.image-button {
  width: 100%;
  display: flex;
  height: 250px;
  border: 1px solid #000 !important;
}