@import "../../components/scss/Common";

.reprint-modal {
    .container {
        width: 100%;
        max-width: 487px;

        .button-group-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;


            .button {
                width: 48%;
                height: 50px;
                border-radius: 12px;
                margin-top: 30px;

            }
        }

        .body {
            padding: 24px;
            color: $colorBlack;

            .title {
                padding-bottom: 16px;
                margin-bottom: 16px;
            }
        }
    }
}

table thead th {
    border-bottom: none !important;
}

.a-tags {
    display: flex;
    color: red;
    justify-content: end;
}

.products {
    text-decoration: underline;
}

.remove-button {
    background-color: transparent;
    border: none;

}




.products-list {
    overflow-x: auto;
    margin-top: 20px;

    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            th {
                text-align: left;
                padding: 10px 5px;
                border-bottom: 2px solid $colorBlack;
            }
        }

        tbody {
            position: relative;
            margin-top: 4px;
            display: flex;
            flex-direction: column;
            margin-left: 4px;
            .items {
                font-size: 13px;

                tr {

                    margin-bottom: 10px;
                }

                td {
                    padding-left: 5px;
                    flex: 1;

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}
 .labels{
     margin: 20px 0 15px 20px;
     font-size: 18px;
 }

.amount-product input {
    font-size: 20px;
    text-indent: 8px;

}

.amount-wrapper {
    display: flex;
    align-items: center;

    .slash {
        margin-left: 16px;
        position: absolute;
        font-size: 23px;
        margin-bottom: 3px;
    }

    .amount-product {
        flex: 1;
    }
}
