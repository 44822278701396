@import "src/components/scss/Common";
.payment-method-table-container {
    .is-empty-data {
        background-color: $colorWhite;
        p {
            font-style: italic;
            text-align: center;
            vertical-align: middle;
            padding: $space32 $space12;
            color: $colorDarkGray;
        }
    }
}
