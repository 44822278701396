@import "src/components/scss/Common";

.table-scroll {
  display: block;
  text-align: center;
}

.table-container {
  position: relative;

  @include media-breakpoint-down(sm) {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorWhite;
    transition: 200ms ease-in-out z-index, 200ms ease-in-out opacity;
    z-index: -1;
    opacity: 0;
    position: absolute;
    width: 100%;

    &.show {
      z-index: 5;
      opacity: 1;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      border-radius: 12px 12px 0 0;
      overflow: hidden;

      th {
        background-color: $colorWhite;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        vertical-align: middle;
        border-bottom: 6px solid $colorGray;
        padding: 22px 10px;

        &:first-child {
          border-top-left-radius: 12px;
          padding-left: 22px;
        }

        &:last-child {
          border-top-right-radius: 12px;
          padding-right: 22px;
        }

        &.center {
          .table-header-container {
            justify-content: center;
          }
        }

        &.right {
          .table-header-container {
            justify-content: flex-end;
          }
        }

        .table-header-container {
          display: flex;
          align-items: center;

          .button-sort .button-content {
            display: inline-flex;
            align-items: center;
            overflow: visible;

            .icon-sort {
              display: inline-flex;
              flex-direction: column;
              margin-right: $space8;

              .icon-ascendant {
                width: 8px;
                padding: 1.5px;
              }

              .icon-descendant {
                padding: 1.5px;
                width: 8px;
                opacity: 1;

                img+img {
                  margin-top: 2px;
                }

                // &:hover {
                //   opacity: 1;
                // }
              }

              &.none {
                transition: 200ms ease-in-out opacity;
                opacity: 0;
              }
            }

            &:hover,
            &:focus {
              .icon-descendant {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    tbody {
      position: relative;
      margin-top: 4px;

      .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $colorWhite;
        transition: 200ms ease-in-out z-index, 200ms ease-in-out opacity;
        z-index: -1;
        opacity: 0;

        &.show {
          z-index: 5;
          opacity: 1;
        }
      }

      tr {
        cursor: pointer;

        td {
          background-color: $colorWhite;
          padding: 20px 10px;
          text-align: left;
          vertical-align: middle;
          font-size: 16px;
          transition: 100ms ease-in-out background-color;

            &:first-child {
                padding-left: 22px;
            }

            &:last-child {
                padding-right: 22px;
            }

          &.center {
            text-align: center;
          }

          &.right {
            text-align: right;
          }

          &.controls {
            width: 1px;
            white-space: nowrap;
            text-align: right;

            .button {
              display: inline-block;
              vertical-align: middle;
              margin: 0 $space6;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          &.status-container {
            font-style: italic;
            text-align: center;
            vertical-align: middle;
            padding: $space32 $space12;
            color: $colorDarkGray;
          }

          input {
            background-color: transparent;
            padding: $space8;
            border: none;
            border-bottom: 1px solid $colorGray;
            border-radius: 0;
          }

          .field-currency {
            input {
              text-align: right;
            }
          }
        }

        &:hover {
          td {
            background-color: $colorLightGreen;
          }
        }
          &:active {
              td {
                  background-color: $colorLightGreen;
              }
          }

      }

      .selected td {
        background-color: $colorLightGreen;
      }
    }
  }
}
