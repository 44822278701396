@import "src/components/scss/Common";

.cancelReasonModal {
    label {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;

        input {
            width: 16px;
            margin-right: 10px;
        }
    }

    textarea {
        border: 1px solid #707070;
        border-radius: 4px;
    }
}
