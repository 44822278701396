.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;

    .profile-container {
        padding: 4px 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 33px;
        background-color: #FFF;
        width: 345px;
        position: relative;
        z-index: 3;
        cursor: default;

        .profile-picture {
            width: 30px;
            height: 30px;
            overflow: hidden;
            border-radius: 50%;

            .size-main-menu {
                width: 30px;
                height: 30px;
            }
        }

        box-shadow: 0px 3px 6px #00000029;

        .profile-arrow {
            display: flex;
            transition: transform .1s linear;
            transform: rotateZ(90deg);

            &.active {
                transform: rotateZ(-90deg);
            }
        }

        p {
            font-size: 22px;
        }
    }

    .profile-dropdown {
        position: absolute;
        top: 20px;
        right: 0px;
        width: 345px;
        padding: 22px 0 8px;
        border-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: #FFF;
        z-index: 1;
        box-shadow: 0px 3px 6px #00000029;
        cursor: default;

        ul {
            list-style: none;

            li {
                padding: 5px 8px;
                font-size: 15px;
                width: 100%;
                cursor: default;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }
}

