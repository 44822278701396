@import "../../components/scss/Common";

.box {
  padding: 8px;
  border-radius: 12px;
  background-color: $colorWhite;
  margin-bottom: 30px;
  width: 100%;

  .active {
    color: $colorGreen
  }

  .deactivated {
    color: $colorRed
  }

  .status-title span{
    font-size: 22px;

    .status {
      font-weight: 700;
    }
  }

  .content-info,
  .content-details {
    margin: 4px 0 0 4px;
  }

  .content-info {
    font-size: medium;
  }

  .content-details {
    margin-top: 10px;
  }
}

@media (max-width: 1024px) {
  .box {
    .status-title span {
      font-size: medium;
    }

    .content-info {
      font-size: small;
    }
  }
}

@media (max-width: 768px) {
  .box {
    .status-title span {
      font-size: small;
    }
    
    .content-info {
      font-size: smaller;
    }
  }
}
