@import "src/components/scss/Common";

.purchase-detail {
  .back-button {
    margin: 0;
  }

  .button-container {
    button {
      margin-bottom: 29px;
    }
  }

  .check-button {
    height: 30px;
  }

  .similars-container {
    padding-left: 20px;
    list-style: none;
    font-style: italic;
  }

  .table-container {
    table {
      thead {
        th {
          height: 62px;
          text-align: center;

        }
      }

      tbody tr td {
        input {
          border: 2px solid $colorBlue;
          border-radius: 12px;
          text-align: center;
          height: 30px;
          width: 100%;
        }
      }

      .button-row {
        padding: 0 5px 0 0;

        button {
          display: inline-block;
        }
      }
    }
  }

  .update-purchase {
    button {
      color: $colorWhite;
    }
  }

  .pills-container {
    .text {
      margin: 0 65px;
    }
  }

  .info-container {
    p {
      font-style: italic;
    }
  }

  .count-selected {
    width: 70px;

    input {
        background-color: #E8E8E8;
        border: none !important;
    }
  }
}

.suggestion-tooltip .tooltip-container .tooltip{
  align-items: flex-start;
  margin-top: 3px;
  
  .tooltip-text {
    top: 30%;
    transform: translateY(-50%);
  }
}