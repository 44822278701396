@import "Loading";

a.button,
a.button:hover,
a.button:visited,
a.button:focus,
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: relative;
  background-color: $colorGreen;
  color: $colorBlack;
  border: none;
  border-radius: 8px;
  padding: $space18;
  cursor: pointer;
  font-weight: $fontWeightSemibold;
  text-align: center;
  text-decoration: none;
  font-style: normal;

  &.transparent {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: unset;
    font-weight: unset;
    font-style: unset;
    text-decoration: unset;

  }

  .button-content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    img {
      display: block;
    }
  }

  .loading {
    position: absolute;
    left: 50%;
    margin-left: ($loadingSizeSmall / -2);
  }

  &.is-loading {
    cursor: wait;
  }

  &.button-normal {
  }

  &.button-tiny {
    padding: $space4 $space6;
    font-size: 15px;
  }

  &.button-shadow {
    font-size: 14px;
    box-shadow: 0 3px 6px #00000029;
  }

  &.button-login {
    //font-size: 18px;
    color: $colorWhite;
    padding-top: $space20;
    padding-bottom: $space20;
  }

  &.button-color-orange {
    background-color: $colorOrange;
  }

  &.button-color-gray {
    background-color: $colorDarkGray;
    color: $colorWhite;
  }

  &.button-color-red {
    background-color: $colorRed;
    color: $colorWhite;
  }

  &.button-color-blue {
    background-color: $colorBlue;
    color: $colorWhite;
  }

  &.button-color-white {
    background-color: $colorWhite;
  }

  &.button-font-color-light {
    color: $colorWhite;
  }

  &.button-font-color-default {
  }

  &.has-icon {
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: right $space16 center;
    padding-left: $space16 * 2;
    padding-right: $space16 * 2;
  }

  &:disabled {
    cursor: auto;
    opacity: 0.7;
  }
}
