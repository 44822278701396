@import "../../components/scss/Common.scss";
.button-voucher button {
    background-color: transparent;
}

.button-icon {
    font-size: 25px;
}

.tooltip-voucher .tooltip-container .tooltip {
    align-items: flex-start;
}

.actions-column {
    text-align: center;
}

.actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
}