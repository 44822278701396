.stock {
    .field-inputs {
        label {
            margin: 0 8px;

            &:first-child {
                margin: 0;
            }
        }

        input {
            margin-right: 5px;
        }
    }

    .border-left {
        border-left: 3px solid #EFEFEF;
    }

    .border-right {
        border-right: 3px solid #EFEFEF;
    }

    .red {
        color: #F54141;
    }

    .green {
        color: #378B0D;
    }

    .update-purchase {
        button {
            color: #FFF;
        }
    }

    .count-selected {
        width: 70px;

        input {
            border-radius: 12px;
            height: 30px;
            width: 100%;
            text-align: center;
            background-color: #E8E8E8;
            border: none !important;
        }

        /* Para WebKit (Chrome, Safari, Edge) */
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Para Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

    }
}