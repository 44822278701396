.modal-menu-item .container {
    width: 1000px;

    .validation-error {
        text-align: left;
    }

    .section-header {
        color: #000;
    }
}
