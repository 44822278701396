@import "../../components/scss/Common.scss";

// Define missing color variables
$colorOrange: #FFA500;
$colorMediumGrayOpacity05: rgba(128, 128, 128, 0.5);
$colorWhite: #FFFFFF;

.info-table {
    width: 100%;

    .title {
        padding: 4px 10px;
        background-color: $colorOrange;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .button-new-transaction {
            background: none;
            font-style: italic;
            text-decoration: underline;
            font-size: 14px;
            padding: 0;
            margin-right: 0;

        }
    }

    .list {
        margin-top: 6px;

        &-title {
            padding: 4px 10px;
            background-color: $colorMediumGrayOpacity05;

            font-style: italic;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .list-body-content {
            max-height: 240px;
            overflow-y: auto;

            .list-body {
                padding: 10px 10px;
                background-color: $colorWhite;

                &-title {
                    text-align: left;
                }

                &-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
}