@import 'src/components/scss/Common';

.copy-address {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.total-row {
    td {
        background-color: #DCDCDC !important;
        font-weight: bold;
        cursor: default;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
}

.purchase-detail-table {
    margin-top: 20px;
    margin-bottom: 10px;

    thead > tr {
        th {
          background-color: #E3A208;
        }
    }
}

.virtual-order-form-container {
    border-radius: 8px;
    border-top-left-radius: 0;
    padding: 24px 16px;
    background-color: $colorLightGray;
}

.payment-card {
    border: 1px solid #ccc;
    border-radius: 7px;
    max-width: 350px;
    padding: 15px;

    .payment-flag {
        font-style: italic;
        font-size: 20px;
        font-weight: 700;
    }

    .payment-number {
        display: flex;
        justify-content: start;
        align-items: center;

        .asterisks {
            padding-top: 16px;
            font-size: xx-large;
            font-weight: 300;
        }
        .number {
            margin: 0 5px;
            font-size: 30px;
        }
    }

    .validity {
        font-size: 14px;
        margin-bottom: 15px;
    }
}

.crud-list-order-detail {
    overflow-x: hidden;
}
