@import "../../components/scss/Common.scss";
.crud-list {
    .dash-table-container {
        table {
            thead {
                th:last-child {
                    border-top-right-radius: 0px !important;
                }

                th:first-child {
                    border-top-left-radius: 0px !important;
                }
            }

            tr {
                td {
                    padding: 10px !important;

                    .field-group {
                        margin-bottom: 0px !important;
                    }
                }

                .crossed-out-line {
                    text-decoration: line-through;
                }
            }

            .adm-total-row {
                td {
                    border-top: 1px solid $colorBlack;
                    background-color: $colorLightGreen;
                }
            }
        }
    }

    .adm-options {
        margin-top: 40px;
        .decision-making {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            margin-bottom: 28px;

            .difference-in-value {
                display: flex;
            }

            p {
                font-size: larger;
            }

            .field-inputs {
                label {
                    font-size: larger;
                    margin-right: 0 !important;
                }
            }
        }
        .adm-buttons-box {
            .controls {
                .row {
                    .buttons-controll {
                        padding: 12px !important;
                    }
                }
            }
        }
    }
}