.border-machine-error {
  border: 1px solid #B92020;
}

.type-machine-error {
  margin-top: 6px;
  margin-left: 18px;
  font-size: 16px;
  color: #B92020;
  font-weight: 600;
}