.section-container {
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.section-content {
  display: none;

  &.open{
    display: block;
  }
}

.icon-open {
  img {
    transform: rotate(180deg);
  }
}