@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import "bootstrap/scss/mixins";
//---------------------------------------------------------
// Fonts
//---------------------------------------------------------
$fontSourceSansPro: 'Source Sans Pro';
$fontWeightLight: 100;
$fontWeightSemibold: 600;

//---------------------------------------------------------
// Colors
//---------------------------------------------------------
$colorLightGray: #F1F1F1;
$colorGray: #E8E8E8;
$colorLightGreen: #E1F6ED;
$colorGreen: #41B983;
$colorMediumGreen: #69c79e;
$colorGreenOpacity05: #A1DCC2;
$colorBlack: #000;
$colorBlackOpacity08: #000000cc;
$colorBlackBoxShadow: #00000029;
$colorMediumGrayOpacity05: #88888880;
$colorMediumGray: #A79C9C;
$colorDarkGray: #707070;
$colorWhite: #FFF;
$colorRed: #B92020;
$colorRedOpacity05: #b9202080;
$colorOrange: #E3A208;
$colorBlue: #258EC4;
$colorTextSecondary: #484848;

//---------------------------------------------------------
// Space and margins
//---------------------------------------------------------
$space4: 4px;
$space6: 6px;
$space8: 8px;
$space10: 10px;
$space12: 12px;
$space14: 14px;
$space16: 16px;
$space18: 18px;
$space20: 20px;
$space24: 24px;
$space28: 28px;
$space30: 30px;
$space32: 32px;
$space30: 30px;
$space35: 35px;
$space42: 42px;
$space50: 50px;
$space60: 60px;
$space80: 80px;
$space120: 120px;

//---------------------------------------------------------
// Config text
//---------------------------------------------------------
.text-truncate-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 2;
  max-width: 300px;
}

.text-ellipsis-truncate-lines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}