@import "../../../components/scss/Common";

.stock-check-form {
  .product-info {
    width: 100%;
    background-color: #FFF;
    padding: 20px 18px;
    border-radius: 12px;

    th, td {
        font-size: 14px;
        color: #000;
        background-color: transparent;
        border-bottom: none;
        padding-right: 5px;
        vertical-align: top;
    }

    th {
        padding-bottom: 12px;
    }
  }

  .button-content {
    color: #FFF !important;
  }

  .head {
    font-size: 18px;
    color: #F30F0F;
    text-align: center;
  }

  .subhead {
    font-size: 16px;
    color: #000;
    opacity: .8;
    text-align: center;
    margin-top: 8px;
    font-weight: normal;
  }

  .product-divergence-info {
    width: 100%;
    background-color: #D1D1D1;
    border-radius: 8px;

    .product-divergence-info-item {
        padding: 14px 20px;
        display: flex;
        justify-content: space-between;

        &.footer {
            font-weight: bold;
            border-top: 1px solid #A4A4A4;

            .divergence-count {
                color: #F30F0F;
            }
        }
    }
  }

}

.table-row.hasDivergence {
    color: red;
}
