@import "./scss/Common";

.search-exchange-modal {
  .container {
    width: 1000px;
    height: 740px;
  }
}

.selected-exchange {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background-color: $colorLightGreen;
  padding: $space14;

  span {
    margin-right: 123px;
  }
}