@import "src/components/scss/Common";

.sales-form {
    .amount-field input {
        font-size: 25px;
        text-align: center;
    }

    .quantity-field {
        position: relative;
        display: flex;
        align-items: center;
        p {
            position: absolute;
            left: 18px;
            top: 16px;
            z-index: 1;
            padding-right: 5px;
        }

        .amount-field input {
            text-align: right;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-left: 70px
        }
    }

    .text_filter {
        width: 100%;

        .input-container input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .middle-container {
        height: 100%;
        overflow-y: scroll;
        margin-top: 6px;
        background-color: #fff;

        &::-webkit-scrollbar-track {
            width: 10px;
        }
    }

    .products_header {
        width: 100%;
        padding-right: 15px;
        border-radius: 12px 12px 0px 0px;
        background-color: #fff;

        th {
            padding: 24px 18px;
            border: none;
            width: 10%;
            text-align: center;
            font-size: 16px !important;
        }
    }

    .products_body {
        width: 100%;
        background-color: #fff;
        padding: 14px 0;
        font-size: 20px;

        td {
            padding: 10px 18px;
            width: 10%;
            text-align: center;
        }
    }

    .totals__container {
        display: flex;
        width: 100%;
        height: 170px;

        .totals__wrapper {
            width: 55%;
            display: flex;
            flex-direction: column;

            .amount-sale {
                p {
                    font-size: 20px !important;
                }
            }
        }
    }

    .totals__sale {
        height: 100%;
        width: 45%;
        border-radius: 0px 12px 12px 0px;
        background-color: #fff;
        padding: 20px;
        text-align: right;
        font-size: 70px;

        p {
            font-weight: 600;
            font-size: 22px;
        }
    }
    
    .products-exchange {
        color:red;
    }

    .remove-customer {
        color: #111111;
    }

    .clickable:hover {
        cursor: pointer;
    }
}

.field-quantity-product {
    input {
        text-align: center;
    }
}