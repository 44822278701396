.btn-link-add-user-to-coupon,
.btn-link-user-not-existis {
    border: none;
    background-color: transparent;
    p {
        text-decoration: underline;
        font-size: medium;
    }

    &:hover {
        cursor: pointer;
    }
}

.cpf-invalid {
  color: red;
}