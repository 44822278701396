@import "src/components/scss/Common";
@import "./MainMenuGroup";

.main-menu {
  display: flex;
  flex-direction: column;
  background-color: $colorWhite;
  box-shadow: 0px 3px 6px #00000029;
  height: 100%;
  padding: $space20 $space6 0;
  width: 310px;
  transition: 200ms ease-in-out width;

  @include media-breakpoint-down(sm) {
    width: 100%;
    position: fixed;
    z-index: 100;
  }

  .user-container {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 0 $space6 $space20;
    z-index: 10;
    box-shadow: 0 0 5px 5px rgba(255,255,255,1);

    .user-info {
      flex: 1;
      padding-left: $space32;
      transition: 200ms ease-in-out width, 200ms ease-in-out visibility;

      .user-name {
        font-weight: bold;
        font-size: 20px;
        padding-right: $space8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .job-title {
        font-size: 14px;
        color: $colorDarkGray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .user-controls {
        padding-right: $space6;
        overflow-x: hidden;
        transition: 200ms ease-in-out width, 200ms ease-in-out visibility;
      }
    }

    .picture-container {
      &:after {
        display: inline-block;
        content: ' ';
        height: 1px;
        background-color: $colorMediumGray;
        margin-top: $space18;
        left: 0;
        top: 100%;
        width: 100%;
        position: absolute;
        transition: 200ms ease-in-out opacity;
        opacity: 0;
      }
    }
  }

  &.collapsed {
    width: 72px;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    .user-container {
      .user-info,
      .user-controls {
        width: 0;
        visibility: hidden;
      }
    }

    .menu-container {
      .main-menu-group {
        .group-icon {
          padding-left: 0;
        }

        .group-title {
          width: 0;
          padding: 0;
          flex: unset;
          visibility: hidden;
        }

      .group-collapse {
        display: none;
      }

        @include collapseGroup();

        &:not(.collapsed) {
          .group-header {
            background-color: $colorGreen;
          }
        }
      }
    }

    .picture-container {
      &:after {
        opacity: 1;
      }
    }
  }

  .menu-container {
    flex: 1;
    position: relative;

    .scroll-container {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      scroll-behavior: smooth;
      padding-top: $space10;
      padding-bottom: $space20;

      &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #000;
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .logoff .group-title {
    color: #e3a208 !important;
  }

  .close-button {
    display: inline-flex;
    align-items: center;
    font-style: normal;
    color: $colorBlack;
    font-size: 16px;

    img {
        margin-right: $space8;
    }
  }
}
