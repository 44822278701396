@import "src/components/scss/Common";
@import "src/admin/sales/SalesPaymentModal";

.screen-header {
  padding: 16px 0;
  border-bottom: 1px solid $colorMediumGray;
  margin-bottom: $space24;
  display: flex;
  justify-content: space-between;

  .header {
    display: flex;
    align-items: center;

    .open-menu {
        margin-right: 15px;

        a {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #FFF;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 25px;
                height: 25px;
            }
        }
    }

    h1 {
      font-size: 22px;
      font-weight: bold;
      padding: 0;
      margin: 0 0 4px;
    }

    ul.breadcrumb {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: inline-flex;
        align-items: center;
        font-size: 14px;

        a {
          text-decoration: none;
          font-style: normal;
          color: $colorBlack;
        }

        &:after {
          content: '>';
          margin: 0 4px;
        }

        &:last-child {
          &:after {
            content: '';
          }
          font-weight: bold;
        }
      }
    }
  }

  .back-button {
    margin-left: 24px;
    font-size: 14px;
  }
}
