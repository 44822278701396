.actions-container {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

.product-name {
    font-style: normal;
    text-decoration: none;
    color: #000 !important;

    &:hover {
        font-style: normal;
        color: #000 !important;
    }
}
